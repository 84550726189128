
import BasicLayout from '~/layouts/basic'
import { mapState } from 'pinia'
import { useSiteStore } from '~/store/site'

export default {
    head() {
        return {
            title: 'SalesPop | Notifications',
        }
    },
    components: {
        BasicLayout
    },
    computed: {
        ...mapState(useSiteStore, ['notifications']),
    }
}
