import { render, staticRenderFns } from "./notifications.vue?vue&type=template&id=092322c8&scoped=true&lang=pug"
import script from "./notifications.vue?vue&type=script&lang=js"
export * from "./notifications.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "092322c8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EmptyNotifications: require('/opt/build/repo/components/EmptyState/EmptyNotifications.vue').default,OneNotification: require('/opt/build/repo/components/DataDisplay/OneNotification.vue').default,GenericList: require('/opt/build/repo/components/DataDisplay/GenericList.vue').default,Card: require('/opt/build/repo/components/Layout/Card.vue').default})
